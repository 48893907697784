const isNotNumeric = (n: any): boolean => isNaN(parseFloat(n)) || !isFinite(n);
const separator = / |,/;

export const formatThousands = (n: any, space = false): any => {
  if (isNotNumeric(n)) {
    return n;
  }
  const split = n.toString().split('.');
  let spaced = split[0]
    .toString()
    .split('')
    .reverse()
    .reduce(
      (str: string, char: string, i: number): string =>
        (i + 1) % 3 ? `${str}${char}` : `${str}${char}${space ? ' ' : ','}`,
      ''
    )
    .split('');

  // remove trailing comma or space if present
  if (spaced[spaced.length - 1].match(separator)) {
    spaced.pop();
  } else if (spaced[spaced.length - 1] === '-' && spaced[spaced.length - 2].match(separator)) {
    spaced.splice(-2, 1);
  }

  spaced = spaced.reverse().join('');
  return split[1] ? `${spaced}.${split[1]}` : spaced;
};

// 0-9   | 2dp
// 10-99 | 1dp
// 100+  | 0dp
export const formatDecimalPlaces = (n: any, dp?: number): any => {
  if (isNotNumeric(n)) {
    return n;
  }
  const numericN: number = parseFloat(n);
  if (numericN === 0) {
    return '0';
  }
  if (typeof dp === 'number') {
    return numericN.toFixed(dp);
  }

  const abs = Math.abs(numericN);
  if (abs < 10) {
    return numericN.toFixed(2);
  }
  if (abs < 100) {
    return numericN.toFixed(1);
  }
  return `${Math.round(numericN)}`;
};

export const formatNumber = (n: any, dp?: number) => formatThousands(formatDecimalPlaces(n, dp));
export const flattenSysCode = (sysCodeName, sysCodeData) => {
  const flatten = {}
  flatten[`${sysCodeName}.code`] = sysCodeData[sysCodeName].code
  flatten[`${sysCodeName}.description_eng`] = sysCodeData[sysCodeName].description_eng
  flatten[`${sysCodeName}.description_lao`] = sysCodeData[sysCodeName].description_lao
  flatten[`${sysCodeName}.code_order`] = sysCodeData[sysCodeName].code_order

  return flatten;
};

export const getCumulativeFromWorkQuery = (payload: any, numberOfYears: number, startYear: number) => {
  const rawSeries = new Array(numberOfYears + 1).fill(1).map((x, idx) => ({
    year: idx + startYear,
    hi: 0,
    scenario_number: null,
    REPSM: 0,
    REPMD: 0,
    REPLA: 0,
    year_total_cost: 0,
    cumulative_cost: 0,
    number_recon: 0,
  }));

  payload.forEach((p) => {
    const year = p.year ?? p.repair_year;
    rawSeries[year - startYear].year = year;
    rawSeries[year - startYear].hi = p.hi;
    rawSeries[year - startYear].scenario_number = p.scenario_number;
    rawSeries[year - startYear].year_total_cost += (isNaN(p.work_cost) || !p.work_cost ? 0 : p.work_cost);
    rawSeries[year - startYear].number_recon +=
      p.work_type === 'RECON' ? 1 : 0;

    if (p.repair_level) {
      rawSeries[year - startYear][p.repair_level] += (isNaN(p.work_cost) || !p.work_cost ? 0 : p.work_cost);
    }
  });

  let cumul = 0;
  rawSeries.forEach((s, idx) => {
    cumul += s.year_total_cost ?? 0;
    rawSeries[idx].cumulative_cost = cumul;
  });

  return rawSeries;
}

export const getSeriesColors = (serie: string) => {
  if (serie === 'REPSM') {
    return '#bef264'
  }
  if (serie === 'REPMD') {
    return '#fdba74'
  }
  if (serie === 'REPLA') {
    return '#fca5a5'
  }
  if (serie === 'HI') {
    return '#0284c7'
  }
  if (serie === 'HI') {
    return '#0284c7'
  }
  if (serie === 'WORK') {
    return '#16a34a'
  }
  if (serie === 'SCENARIO_0') {
    return '#7fc97f'
  }
  if (serie === 'SCENARIO_1') {
    return '#beaed4'
  }
  if (serie === 'SCENARIO_2') {
    return '#fdc086'
  }
  if (serie === 'SCENARIO_3') {
    return '#ffff99'
  }
  if (serie === 'SCENARIO_4') {
    return '#386cb0'
  }
  if (serie === 'SCENARIO_5') {
    return '#f0027f'
  }
  if (serie === 'SCENARIO_6') {
    return '#bf5b17'
  }
  if (serie === 'SCENARIO_7') {
    return '#666666'
  }
  return '#d4d4d8'
}