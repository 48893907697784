export const hi_headers = [
 { "text": ">=80", "value": ">=80", "key": ">=80" },
 { "text": "%", "value": ">=80_%" },
 { "text": "60-80", "value": "60-80", "key": "60-80" },
 { "text": "%", "value": "60-80_%" },
 { "text": "40-60", "value": "40-60", "key": "40-60" },
 { "text": "%", "value": "40-60_%" },
 { "text": "20-40", "value": "20-40", "key": "20-40" },
 { "text": "%", "value": "20-40_%" },
 { "text": "<20", "value": "<20", "key": "<20" },
 { "text": "%", "value": "<20_%" },
]