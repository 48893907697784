<template>
    <v-container fluid class="reporting">
        <div class="d-flex flex-column">
            <v-layout child-flex v-for="metric in metrics" :key="metric.key"
                :class="[window.width < 1700 ? 'flex-column' : '', 'report-row']">
                <v-card class="ma-2 d-flex flex-column">
                    <v-data-table :footer-props="{ 'items-per-page-text': '' }" :no-data-text="$t('No data available')"
                        :headers="metric.headers" :items="metric.data" :items-per-page="-1" class="elevation-1"
                        hide-default-footer dense>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>{{ metric.title }}
                                    <DataExport :data="metric.data" :headers="metric.headers" :file-name="metric.title" />
                                </v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                    </v-data-table>
                </v-card>
                <v-card class="ma-2 d-flex flex-column report-chart">
                    <BaseBar :series="metric.chartSeries" :title="metric.title" legend-bottom stacked :height="600" />
                </v-card>
            </v-layout>
        </div>
    </v-container>
</template>


<script>
import BridgeService from "@/services/BridgeService";
import BaseBar from "@/components/main/BaseBar.vue";
import { hi_headers } from "@/components/bridgeManagement/reporting.headers"

export default {
    name: "Reporting",
    components: {
        BaseBar
    },
    data() {
        return {
            reportsList: [{
                key: 'bridge_material',
            }, {
                key: 'age',
            }, {
                key: 'element-damage'
            }, {
                key: 'elements-hi',
            }, {
                key: 'components-hi',
            }, {
                key: 'spans-hi',
            }, {
                key: 'bridges-hi',
            }],
            payload: null,
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    created() {
        window.addEventListener('resize', this.onWindowsResize);
        this.onWindowsResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowsResize);
    },
    computed: {
        metrics() {
            if (!this.payload) {
                return []
            }
            return this.reportsList.map(report => {
                const data = this.pivotData(this.payload[report.key]).map(row => ({
                    ...row,
                    province_name: this.getProvinceName(row),
                    total: this.getTotal(row),
                })).sort((a, b) => Number(a.bridge_province) - Number(b.bridge_province))


                let headers = [];

                if (report.key.includes('-hi')) {
                    headers = JSON.parse(JSON.stringify(hi_headers))
                }
                else {
                    headers = this.payload[report.key].reduce((acc, current) => {
                        if (!acc.some(item => item[this.description] === current[this.description])) {
                            acc.push(current);
                        }
                        return acc;
                    }, []).sort((a, b) => a.code_order - b.code_order).map(x => [{
                        text: this.$t(x[this.description]),
                        value: x[this.description],
                        key: x[this.description],
                    }, {
                        text: '%',
                        value: `${x[this.description]}_%`,
                    }]).flat()
                }

                headers.unshift({
                    text: this.$t("bridge_province"),
                    value: 'province_name',
                })

                headers.push({
                    key: 'total',
                    text: this.$t("total"),
                    value: 'total',
                })

                const chartSeries = headers.filter(x => x.text !== '%' && x.value !== 'province_name').map(header => ({
                    name: header.text,
                    data: data.map(x => ({
                        x: x.province_name,
                        y: isNaN(Number(x[header.text])) ? 0 : Number(x[header.text])
                    }))
                }))
                const title = this.$t(`${report.key}_report`)


                const totalRow = {
                    province_name: this.$t("total"),
                    bridge_province: '0'
                }

                headers.forEach(header => {
                    if (header.key) {
                        totalRow[header.key] = data.reduce((acc, current) => {
                            const value = Number(current[header.key])
                            if (isNaN(value)) {
                                return acc
                            }
                            acc = acc + value
                            return acc
                        }, 0)
                    }

                })
                
                data.push(totalRow)

                return {
                    ...report,
                    data,
                    headers,
                    title,
                    chartSeries
                }
            })
        },
        description() {
            return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
        },
    },
    async mounted() {
        this.payload = (await BridgeService.getReports()).data;
    },
    methods: {
        onWindowsResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        pivotData(data) {
            const res = {};
            data.forEach(row => {
                if (!res[row.bridge_province]) {
                    res[row.bridge_province] = {
                        bridge_province: row.bridge_province
                    }
                }
                res[row.bridge_province][row[this.description]] = row.value;
                res[row.bridge_province][`${row[this.description]}_%`] = row.percentage;
            })
            return Object.keys(res).map(key => res[key])
        },
        getProvinceName(row) {
            return row.bridge_province ? this.$store.state.adm.provincesList.find(
                (x) => x.id == row.bridge_province
            )[this.description] : ''
        },
        getTotal(row) {
            return Object.keys(row).reduce((acc, current) => {
                if (current === 'bridge_province') {
                    return acc;
                }
                if (current.includes('_%')) {
                    return acc;
                }
                return acc + Number(row[current])
            }, 0)
        }
    }

}
</script>

<style scoped>
.reporting .report-row {
    flex-wrap: wrap;
}

.reporting .report-chart {
    min-width: 600px;
}
</style>